import React from "react";

import { Helmet } from "react-helmet-async";

const Head = ({ title, description }) => {
  return (
    <div>
      <Helmet title={`Tricomx | ${title}`}>
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
        <meta name="description" content={`${description}`} />
      </Helmet>
    </div>
  );
};

export default Head;
