import React from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import Head from "../components/head/head";
import Loading from "../components/UI/Loading/Loading";
import Layout from "../hoc/layout/layout";
import routes from "./routes";

function MainNav() {
    return (
        <>
            <Head
                title="Portabilidad"
                description={"Cámbiate con nosotros y quedate tu número."}
            />
            <Router>
                <React.Suspense fallback={<Loading />}>
                    <Layout>
                        <Switch>
                            {routes.map((route, i) => (
                                <Route
                                    exact
                                    key={route.path + i}
                                    path={route.path}
                                    component={route.component}
                                />
                            ))}
                            <Redirect to="/" />
                        </Switch>
                    </Layout>
                </React.Suspense>
            </Router>
        </>
    );
}

export default MainNav;
