import React from "react";

import classes from "./footer.module.scss";

import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";

const Footer = () => {
    return (
        <footer className={classes.footer}>
            <nav>
                <ul className={classes.socialLinks}>
                    <li>
                        <a
                            className={classes.socialLink}
                            href="https://www.facebook.com/tricomx.contacto/"
                        >
                            <AiFillFacebook
                                className={classes.socialIcon}
                                alt="facebook"
                            />
                        </a>
                    </li>
                    <li>
                        <a
                            className={classes.socialLink}
                            href="https://www.instagram.com/tricom.mx/"
                        >
                            <AiFillInstagram
                                className={classes.socialIconI}
                                alt="instagram"
                            />
                        </a>
                    </li>
                </ul>
            </nav>
            <p className={classes.Copyright}>
                Copyright TRICOMX, © {new Date().getFullYear()}. Todos los
                derechos reservados
            </p>
        </footer>
    );
};

export default Footer;
