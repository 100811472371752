import React from "react";

import classes from "./header.module.scss";
import Logo from "../../UI/Logo/Logo";
import { AiOutlinePhone } from "react-icons/ai";

const Header = (props) => {
    return (
        <>
            <div className={classes.phone}>
                <p>
                    <a href="tel:8001111919">
                        {" "}
                        <AiOutlinePhone /> 800 1111 919
                    </a>
                </p>
            </div>
            <header className={classes.header}>
                <Logo />
                <h1>Portabilidad</h1>
            </header>
        </>
    );
};

export default Header;
