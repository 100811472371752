import React from "react";

const Inicio = React.lazy(() => import("../pages/Inicio"));
const Portabilidad = React.lazy(() => import("../pages/Portabilidad"));
const Success = React.lazy(() => import("../pages/Success"));

const routes = [
    {
        path: "/success",
        component: Success,
    },
    {
        path: "/:id",
        component: Portabilidad,
    },
    {
        path: "/",
        component: Inicio,
    },
];

export default routes;
