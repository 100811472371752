export default async function errorHandler (asyncFn, rejectWithValue) {
    try {
        const fetchResponse = await asyncFn;
        if (fetchResponse) {
            if (fetchResponse.data) {
                const { data } = fetchResponse;
                if (data.response) {
                    const { response } = data;
                    return response;
                }
                return data;
            }
        }
        return fetchResponse;
    } catch (err) {
        if (err.response) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue({
            detail: "Hubo un error al procesar tu solicitud. Contáctanos",
            code: 500,
            slug: "unknown_error",
        });
    }
}
