import { createSlice } from "@reduxjs/toolkit";
import {
    getPortData,
    sendPortData,
} from "../actions/main";

const initialState = {
    loading: "idle",
    currentRequestId: undefined,
    error: null,
    success: false,
    currentStep: 1,
    portability: {},
};

const mainSlice = createSlice({
    name: "main",
    initialState: initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
            state.success = false;
        },
        previousStep: (state) => {
            state.currentStep--;
        },
        nextStep: (state) => {
            state.currentStep++;
        },
        saveData: (state, action) => {
            for (const key in action.payload) {
                state.portability[key] = action.payload[key];
            }
        },
    },
    extraReducers: {
        [getPortData.pending]: (state, action) => {
            if (state.loading === "idle") {
                state.loading = "pending";
                state.currentRequestId = action.meta.requestId;
            }
        },
        [getPortData.fulfilled]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId &&
                action.payload
            ) {
                state.loading = "idle";
                state.currentRequestId = undefined;
                state.error = null;
                state.portData = action.payload;
            }
        },
        [getPortData.rejected]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.error = action.payload || action.error;
                state.currentRequestId = undefined;
            }
        },
        [sendPortData.pending]: (state, action) => {
            if (state.loading === "idle") {
                state.loading = "pending";
                state.currentRequestId = action.meta.requestId;
            }
        },
        [sendPortData.fulfilled]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId &&
                action.payload
            ) {
                state.loading = "idle";
                state.currentRequestId = undefined;
                state.error = null;
                state.success = true;
            }
        },
        [sendPortData.rejected]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.error = action.payload || action.error;
                state.currentRequestId = undefined;
            }
        },
    },
});

export const {
    clearError,
    previousStep,
    nextStep,
    saveData,
} = mainSlice.actions;

export default mainSlice.reducer;
