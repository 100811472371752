import React from "react";

import classes from "./Logo.module.scss";

const Logo = () => {
    const logo = `${process.env.REACT_APP_IMAGES_URL}Logo.png`;
    return (
            <img src={logo} alt="Logo" className={classes.logo} />
    );
};

export default Logo;
