import { useEffect } from "react";

export function useTawkTo() {
  //pass your tawk.to propertyId
  function tawkTo(propertyId) {
    if (!window) {
      throw new Error("DOM is unavailable");
    }

    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    const tawk = document.getElementById("tawkId");
    if (tawk) {
      // Prevent TawkTo to create root script if it already exists
      return window.Tawk_API;
    }

    const script = document.createElement("script");
    script.id = "tawkId";
    script.async = true;
    script.src = `https://embed.tawk.to/6036d5661c1c2a130d621ef0/1evb33663`;
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");

    const first_script_tag = document.getElementsByTagName("script")[0];
    if (!first_script_tag || !first_script_tag.parentNode)
      throw new Error("DOM is unavailable");
    first_script_tag.parentNode.insertBefore(script, first_script_tag);
  }
  
  // initialize tawkto widget
  useEffect(() => {
    const timer = setTimeout(() => {
      tawkTo();
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
}
