import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import SnackbarProvider from "react-simple-snackbar";

import "./App.css";
import MainNav from "./routes";

import mainReducer from "./redux/slices/mainSlice";
import { useTawkTo } from "./utils/hooks/tawk-hook";

const store = configureStore({
  reducer: {
    main: mainReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

function App() {
  //tawkTo
  useTawkTo();
  return (
    <div className="App">
      <HelmetProvider>
        <Provider store={store}>
          <SnackbarProvider>
            <MainNav />
          </SnackbarProvider>
        </Provider>
      </HelmetProvider>
    </div>
  );
}

export default App;
