import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import errorHandler from "./errorHandler";

export const getPortData = createAsyncThunk(
  "main/getPortData",
  async (id, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, error } = getState().main;
    if (
      loading !== "pending" ||
      requestId !== currentRequestId ||
      error !== null
    ) {
      return;
    }
    return await errorHandler(
      Axios.get(`${process.env.REACT_APP_BACKEND_URL}/portability/${id}`),
      rejectWithValue
    );
  }
);
export const sendPortData = createAsyncThunk(
  "main/sendPortData",
  async (signature, { getState, requestId, rejectWithValue }) => {
    const { loading, currentRequestId, error, portability, portData } =
      getState().main;
    if (
      loading !== "pending" ||
      requestId !== currentRequestId ||
      error !== null
    ) {
      return;
    }
    const formattedPort = { ...portability, signature };
    let formData = new FormData();
    for (const key in formattedPort) {
      formData.append(key, formattedPort[key]);
    }
    return await errorHandler(
      Axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/portability/${portData.portabilityId}`,
        formData
      ),
      rejectWithValue
    );
  }
);
