import React from "react";

import Header from "../../components/Navigation/header/header";
import Footer from "../../components/Navigation/footer/footer";

import classes from "./layout.module.scss";
import ScrollToTop from "../../components/Navigation/ScrollToTop";

const Layout = (props) => {
    ScrollToTop();

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <Header />
                {props.children}
            </div>
            <Footer />
        </div>
    );
};

export default Layout;
